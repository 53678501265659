#printPreview thead{
    border: 1px solid black !important;
}

#printPreview th{
    color: black !important;
    border: 1px solid black !important;
    font-weight: 800;
}

#printPreview td{
    color: black !important;
    border: 1px solid black !important;
    font-size: 18px;
    white-space: normal;
}